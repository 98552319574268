import React from 'react'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'
import { Link } from '@reach/router'

import { MainLayout } from '../components'

import '../styles/terms-of-service.scss'

export default function PrivacyPolicy() {
	return (
		<>
			<MainLayout>
				<Helmet title="Deenee: Privacy Policy" />
				<main className="privacy-policy-page container flex flex-col items-center justify-center mb-16">
					<header className="pt-4 mb-3">
						<h1 className="mb-8 text-center font-bold text-2xl leading-snug font-secondary">
							Privacy Policy
						</h1>
						<p>
							Welcome to Deenee! We&apos;re pleased you want to use this
							service. We value the privacy of all of our users and we know you
							care about your privacy and the privacy of your children, so we
							created this Privacy Policy. It explains the nature of the
							information we collect from users and how, and when, we disclose
							or use it. By accessing or using the Deenee mobile application or
							any of its products, services, and/or content made available by
							Alif Creations LTD. (<strong>&quot;Deenee Service&quot;</strong>,{' '}
							<strong>&quot;we&quot;</strong>, and{' '}
							<strong>&quot;us&quot;</strong>
							), you are consenting to collection and use of your information as
							outlined in this Privacy Policy. When you use the Deenee Service,
							you also agree to our{' '}
							<Link
								to="/terms-of-service"
								className="text-blue-500 hover:text-blue-600 focus:text-blue-600"
							>
								<strong>Terms of Service</strong>
							</Link>
							.
						</p>
					</header>
					<section className="mb-3 max-w-full">
						<ol className="list-decimal list-inside">
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									INFORMATION WE COLLECT
								</h2>
								<p className="mb-3">
									In providing our Services, we and our service providers may
									collect the following personal information from you:
								</p>
								<ul className="list-disc list-outside pl-4 ml-4 lg:pl-6 lg:ml-6 mb-3">
									<li>
										Information submitted by you when you register, such as your
										year of birth, email address, password, your child’s age and
										a name for your child’s profile
									</li>
									<li>
										Information uploaded when you purchase a subscription to the
										Deenee Service such as your payment information, mailing
										address, and telephone number.
									</li>
									<li>
										Information that you provide in public forums or otherwise
										voluntarily submit through our product features.
									</li>
								</ul>
								<p className="mb-3">
									We and our service providers may also collect non-personal
									information from you as you use our Services, including:
								</p>
								<ul className="list-disc list-outside pl-4 ml-4 lg:pl-6 lg:ml-6">
									<li>
										<u>Through your browser or device:</u> Certain information
										is collected by most browsers or automatically through your
										device, such as your Media Access Control (MAC) address,
										computer type (Windows or Macintosh), screen resolution,
										operating system name and version, device manufacturer and
										model, language, Internet browser type and version and the
										name and version of the Services you are using. We use this
										information to help ensure that the Services function
										properly.
									</li>
									<li>
										<u>Using cookies:</u> Cookies are pieces of information
										stored directly on the computer that you are using. Cookies
										allow us to collect information such as browser type, time
										spent on the Services, pages visited, language preferences,
										and other traffic data. We and our service providers use the
										information for security purposes, to facilitate navigation,
										to display information more effectively, to personalize your
										experience while using the Services, and to recognize your
										computer in order to assist your use of the Services. We
										also gather statistical information about use of the
										Services in order to continually improve their design and
										functionality, understand how they are used and assist us
										with resolving questions regarding them. We may also use
										cookies or other technologies to track responses to our
										online advertisements on other sites or services. If you do
										not want information collected through the use of cookies,
										there is a simple procedure in most browsers that allows you
										to automatically decline cookies or be given the choice of
										declining or accepting the transfer to your computer of a
										particular cookie (or cookies) from a particular site. You
										may also wish to refer to&nbsp;
										<a
											href="http://www.allaboutcookies.org/manage-cookies/index.html"
											target="_blank"
											rel="noreferrer"
											className="font-bold text-blue-500 hover:text-blue-600 focus:text-blue-600 break-all"
										>
											http://www.allaboutcookies.org/manage-cookies/index.html
										</a>
										. If, however, you do not accept cookies, you may experience
										some inconvenience in your use of the Services. For example,
										we may not be able to recognize your computer, and you may
										need to log in every time you visit.
									</li>
									<li>
										<u>Using pixel tags and other similar technologies:</u>{' '}
										Pixel tags (also known as web beacons and clear GIFs) may be
										used in connection with some Services to, among other
										things, track the actions of users of the Services
										(including email recipients), measure the success of our
										marketing campaigns and compiled statistics about usage of
										the Services and response rates.
									</li>
									<li>
										<u>IP Address:</u> Your IP address is a number that is
										automatically assigned to the computer that you are using by
										your Internet Service Provider. An IP address may be
										identified and logged automatically in our server log files
										whenever a user accesses the Services, along with the time
										of the visit and the pages that were visited. Collecting IP
										addresses is standard practices and is done automatically by
										many websites, applications and other services. We use IP
										addresses for purposes such as calculating usage levels,
										diagnosing server problems, and administering the Services.
										We may also derive your approximate location from your IP
										address.
									</li>
									<li>
										<u>By aggregating information:</u> Aggregated information
										does not identify you or any other user of the Services (for
										example, we may aggregate information to calculate the
										percentage of our users who have a particular telephone area
										code).
									</li>
								</ul>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									HOW WE USE INFORMATION
								</h2>
								<p className="">
									We use these types of information in the following ways:&nbsp;
								</p>
								<ol className="inline">
									<li className="inline">
										1) To provide you with the Deenee Service and any features
										or services you might request (e.g., monthly
										subscription);&nbsp;
									</li>
									<li className="inline">
										2) To send you updates about the Deenee Service (including
										any updates on product features or policies);&nbsp;
									</li>
									<li className="inline">
										3) To communicate with you about purchases or your
										account;&nbsp;
									</li>
									<li className="inline">
										4) To provide you with personally relevant Content or
										product features;&nbsp;
									</li>
									<li className="inline">
										5) To send you feedback on your child&apos;s progress;&nbsp;
									</li>
									<li className="inline">
										6) To investigate and prevent activities that may violate
										our policies or be illegal;&nbsp;
									</li>
									<span>and/or&nbsp;</span>
									<li className="inline">
										7) For gathering anonymous aggregate information from all
										our users to improve the products and services offered
										through the Deenee Service.
									</li>
								</ol>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									HOW WE SHARE INFORMATION
								</h2>
								<p className="mb-3">Your information may be disclosed:</p>
								<ul className="list-disc list-outside pl-4 ml-4 lg:pl-6 lg:ml-6 mb-3">
									<li>
										If you have created an account with the Deenee Service, we
										may publicly associate your profile (or your child’s
										profile), including the profile name and avatar, with a User
										Submission (as defined in our Terms of Service) created by
										such profile. Please keep this in mind when you or your
										children create User Submissions.
									</li>
									<li>
										To our affiliates for the purposes described in this Privacy
										Policy.
									</li>
									<li>
										To our service providers who provide services such as
										website hosting, data analysis, payment processing, order
										fulfillment, information technology and related
										infrastructure provision, customer service, email delivery,
										auditing, and other services.
									</li>
									<li>
										To a third party in the event of any reorganization, merger,
										sale, joint venture, assignment, transfer or other
										disposition of all or any portion of our business, assets or
										stock (including in bankruptcy or similar proceedings).
									</li>
									<li>
										As we believe to be necessary or appropriate:&nbsp;
										<ol className="inline">
											<li className="inline">
												(a) under applicable law, including laws outside your
												country of residence;&nbsp;
											</li>
											<li className="inline">
												(b) to comply with legal process;&nbsp;
											</li>
											<li className="inline">
												(c) to respond to requests from public and government
												authorities, including public and government authorities
												outside your country of residence;&nbsp;
											</li>
											<li className="inline">
												(d) to enforce our terms and conditions;&nbsp;
											</li>
											<li className="inline">
												(e) to protect our operations or those of any of our
												affiliates;&nbsp;
											</li>
											<li className="inline">
												(f) to protect our rights, privacy, safety or property,
												and/or that of our affiliates, you or others;&nbsp;
											</li>
											<span>and&nbsp;</span>
											<li className="inline">
												(g) to allow us to pursue available remedies or limit
												the damages that we may sustain.
											</li>
										</ol>
									</li>
								</ul>
								<p>
									We may use and disclose information that does not identify or
									otherwise directly relate to an individual for any purpose,
									except where we are required to do otherwise under applicable
									law. For example, we may share aggregated information publicly
									to demonstrate growth trends related to the usage of the
									Deenee Service in certain areas.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									TRANSPARENCY AND CONTROLS
								</h2>
								<p className="mb-3">
									We understand and respect your privacy concerns. Accordingly,
									we provide you with the ability to make certain choices
									related to our collection, use, and sharing of your
									information. These choices may include:
								</p>
								<ul className="list-disc list-outside pl-4 ml-4 lg:pl-6 lg:ml-6">
									<li>
										You may edit or delete information in your user account
									</li>
									<li>
										You may make choices regarding subscriptions, updates, and
										product alerts
									</li>
									<li>
										You may tell us if you want to receive offers and promotions
										from us and/or third parties
									</li>
									<li>
										You may request access to the personal information that we
										have collected on your account and ask us to change or
										delete it
									</li>
								</ul>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									CHILDREN’S PRIVACY
								</h2>
								<p>
									We do not collect personal information from children under the
									age of 13. Any child under the age of 13 using the Deenee
									Service must do so with permission from a parent. All full
									names, email addresses, and credit card billing information
									must be from a parent or guardian. We encourage parents to
									take an active role in their children&apos;s activities and
									interests on their devices. When registering your child with
									the Deenee Service, we may ask a series of general questions
									including your child&apos;s age, interests and gender. This
									information is used to customize content to your child&apos;s
									age and interests. We do not use or disclose this information
									for any other purposes except to provide you with quality
									service. Any data that is shared is done so on an anonymous,
									aggregate basis among all users or is related to communicating
									reading behavior patterns to publishers.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									THIRD PARTY SERVICES
								</h2>
								<p>
									This Privacy Policy does not address, and we are not
									responsible for, the privacy, information or other practices
									of any third parties, including any third party operating any
									site or service to which the Services link. The inclusion of a
									link on the Services does not imply endorsement of the linked
									site or service by us or by our affiliates. Although the
									Deenee Service is a safe place for kids, a child may leave the
									Deenee Service using the iOS or Android device Home button.
									Should your child leave the Deenee Service for another
									application or browser, this Privacy Policy no longer applies.
									We have no control over how other applications or sites
									collect, distribute or otherwise use your personal information
									or how they safeguard and secure your personal information.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									SECURITY
								</h2>
								<p>
									In circumstances where your personal information is collected,
									we use reasonable procedural and digital security measures
									that are designed to help protect against loss, unauthorized
									access, disclosure, alteration or destruction of information
									and data, such as password protection and secure socket layer.
									Unfortunately, no data transmission or storage system can be
									guaranteed to be 100% secure. If you have reason to believe
									the security of your account has been compromised, please
									immediately notify us in accordance with the “Contact Us”
									section below
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									CHANGES TO THIS PRIVACY POLICY
								</h2>
								<p>
									We reserve the right to change or modify our Privacy Policy
									from time to time by posting a revised Privacy Policy on our
									site. The “last updated” legend at the top of this page
									indicates when this Policy was last revised. As an ongoing
									user of the Deenee Service, you will be subject to the terms
									of the then-current Privacy Policy. We ask that you refer to
									the Privacy Policy on a continuing basis so that you
									understand our current privacy practices.
								</p>
							</li>
							<li className="list-item mb-3">
								<h2 className="inline-block font-secondary text-xl font-bold mb-3">
									CONTACT US
								</h2>
								<p>
									If you would like to opt out of marketing communications from
									the Deenee Service you can do so by emailing:&nbsp;
									<a
										href="mailto:unsubscribe@deeneeapp.com"
										target="_blank"
										rel="noreferrer"
										className="font-bold text-blue-500 hover:text-blue-600 focus:text-blue-600 break-all"
									>
										unsubscribe@deeneeapp.com
									</a>
									&nbsp;or by following the unsubscribe link in each such
									communication. If you would like to contact us with questions
									or feedback, please&nbsp;
									{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
									<a
										href="mailto:help@deeneeapp.com"
										target="_blank"
										rel="noreferrer"
										className="font-bold text-blue-500 hover:text-blue-600 focus:text-blue-600 break-all"
									>
										contact us here
									</a>
									.
								</p>
							</li>
						</ol>
					</section>
					<footer className="w-full">
						<small className="inline-block text-base mb-8">
							Deenee is owned and operated by Alif Creations LTD, registered
							address at&nbsp;
							<address className="inline not-italic">
								20-22 Wenlock Road, London, England, N1 7GU
							</address>
							.
						</small>
						<p>
							Last updated: <time>March 15, 2020</time>.
						</p>
					</footer>
				</main>
			</MainLayout>
			<ToastContainer />
		</>
	)
}
